
$(document).ready(function () {
    // mainMenuReposition();
    setTimeout(function(){
        $("#navbar").find('.animate').addClass('trigger');
        scrollByHash();
    }, 200);

    checkCookieAcceptance();
    checkMoreArticles();
    initSeznamMap();
});

function initSeznamMap() {
    var center = SMap.Coords.fromWGS84(14.4729400, 48.9766048);
    var m = new SMap(JAK.gel("map"), center, 16);
    m.addDefaultLayer(SMap.DEF_BASE).enable();

    var o = {title:"Posun mapy"};
    var c = new SMap.Control.Compass(o);
    m.addControl(c, {left:"8px", top:"9px"});
    var z = new SMap.Control.Zoom("  Velikost");
    m.addControl(z, {right:"20px", top:"20px"});
    var mouse = new SMap.Control.Mouse(SMap.MOUSE_PAN);
    m.addControl(mouse);

    var layer = new SMap.Layer.Marker();
    m.addLayer(layer);
    layer.enable();

    var card = new SMap.Card();
    card.getHeader().innerHTML = "<strong>Studio 21 s.r.o</strong><br><br>";
    card.getBody().innerHTML = "<address>Česká 29,<br>370 01 České Budějovice<br></address>";

    var options = {
        title: "Studio 21"
    };
    var marker = new SMap.Marker(center, "myMarker", options);
    marker.decorate(SMap.Marker.Feature.Card, card);
    layer.addMarker(marker);

    var pointer2 = new SMap.Control.Pointer({
        type: SMap.Control.Pointer.TYPES.RED,
        snapHUDtoScreen: 20
    });
    m.addControl(pointer2);
    pointer2.setCoords(center);

    var sync = new SMap.Control.Sync({bottomSpace:100, resizeTimeout: 1000});
    m.addControl(sync);
}

function showMoreArticles(e) {
    var hiddenArticles = $('.more-articles').find('.hidden');
    if (hiddenArticles.length > 2) {
        $(hiddenArticles).eq(0).removeClass('hidden');
        $(hiddenArticles).eq(1).removeClass('hidden');
    } else if (hiddenArticles.length === 2) {
        $(hiddenArticles).eq(0).removeClass('hidden');
        $(hiddenArticles).eq(1).removeClass('hidden');
        $(e).hide( 200 );
    } else {
        $(hiddenArticles).eq(0).removeClass('hidden');
        $(e).hide( 200 );
    }
}

function checkMoreArticles() {
    var hiddenArticles = $('.more-articles').find('.hidden');

    if (hiddenArticles.length === 0) {
        $("#news .load-more").hide();
        $('#article .load-more').hide();
    }
}

function mainMenuReposition() {
    $("#navbar").css("top", $(window).scrollTop());
};

function scrollByHash() {
    if (location.hash.substr(1)) {
        scroll_to(location.hash.substr(1));
    }
}

function checkCookieAcceptance() {
    if (localStorage.getItem("cookies")) {
        $('#cookies').css("display", "none");
    }
};

function acceptCookies() {
    $('#cookies').animate({'height': 0});
    localStorage.setItem("cookies", true);
};

$(window).on('resize scroll', function() {
    // mainMenuReposition();
    trigger_animations();
});

$(window).on('load', function(){
    setTimeout(function(){
        $("#intro").find('.animate').addClass('trigger');
        trigger_animations();
    }, 200);
    setInterval(animate_stendr, 6000);
});

// function stendrchangeImage(nr) {
//     var animation_list = [
//         'slide-in-left',
//         // 'slide-in-top',
//         'slide-in-right',
//         'slide-in-bottom',
//         'slide-in-bottom-small',
//         // 'grow-height',
//         // 'grow-width',
//         'appear'
//     ]

//     var image_list = [
//         'stendr12.jpg',
//         'stendr13.jpg',
//         'stendr14.jpg',
//         'stendr15.jpg',
//         'stendr16.jpg',
//         'stendr17.jpg',
//         'stendr18.jpg',
//         'stendr19.jpg',
//     ];

//     if (Array.isArray(nr)) {
//         var animation_arr = [];
//         while(animation_arr.length < 3){
//             var r = Math.floor(Math.random()*animation_list.length);
//             animation_arr.push(r);
//         }

//         var image_arr = [];
//         while(image_arr.length < 3){
//             var r = Math.floor(Math.random()*image_list.length);
//             if(image_arr.indexOf(r) === -1) {
//                 image_arr.push(r);
//             }
//         }

//         nr.forEach(function(element, index) {
//             // $("#stendr_box" + element).attr('class', 'animate');
//             // $("#stendr_box" + element).removeClass('trigger');


//             var new_attr = 'assets/' + image_list[image_arr[index]];
//             var new_animation = animation_list[animation_arr[index]];
//             // $("#stendr_box" + element).addClass(new_animation);
//             if (new_animation === 'undefined') {
//                 new_animation = 'appear';
//             }
//             $("#stendr_box" + element).attr('class', 'animate2 ' + new_animation);

//             setTimeout(function(){
//                 $("#stendr_box" + element + " img").on("load", function(){
//                     $("#stendr_box" + element).addClass('trigger');
//                 }).attr('src', new_attr);
//             }, 1000);
//         });
//     } else {
//         // $("#stendr_box" + nr).attr('class', 'animate');
//         // $("#stendr_box" + nr).removeClass('trigger');

//         var animation = animation_list[Math.floor(Math.random()*animation_list.length)];
//         var new_image = image_list[Math.floor(Math.random()*image_list.length)];
//         var new_attr = 'assets/' + new_image;

//         if (animation === 'undefined') {
//             animation = 'appear';
//         }

//         if (new_attr === $("#stendr_box1 img").attr('src') || new_attr === $("#stendr_box2 img").attr('src') || new_attr === $("#stendr_box3 img").attr('src')) {
//             // New image would be as one already present. Try again.
//             // Just make sure you have more than 2 images in image_list. Unless you like infinite loops.
//             stendrchangeImage(nr);
//             return;
//         } else {
//             $("#stendr_box" + nr).attr('class', 'animate2 ' + animation);
//             // $("#stendr_box" + nr).addClass(animation);
//             setTimeout(function(){
//                 $("#stendr_box" + nr + " img").on("load", function(){
//                     $("#stendr_box" + nr).addClass('trigger');
//                 }).attr('src', new_attr);
//             }, 1000);
//         }
//     }
// }

// function animate_stendr() {
//     var rng = Math.floor((Math.random() * 4) + 1);
//     if (rng === 1) {
//         //do nothing
//     } else if (rng === 2) {
//         //animate one
//         var imageToChange = Math.floor((Math.random() * 3) + 1);
//         stendrchangeImage(imageToChange);
//     } else if (rng === 3) {
//         // Same as above to give changing one image better odds.
//         var imageToChange = Math.floor((Math.random() * 3) + 1);
//         stendrchangeImage(imageToChange);
//     }
//      else if (rng === 4) {
//         //animate all
//         stendrchangeImage([1,2,3]);
//     }
// }

function toggle_mobile_menu() {
    $('#mobile-navbar').toggleClass('toggled');
}

function trigger_animations() {
    $('.content').find('.animate,.animate-bulk').each(function() {
        if (appears($(this))) {
            if ($(this).hasClass("animate-bulk")){
                bulk_animate(this);
            } else {
                $(this).addClass('trigger');
            }
        }
    });
}

function bulk_animate(elem) {
    if ($(elem).hasClass('bulk-200')) {
        var time = 200;
        $(elem).find('.animate-bulk-item').each(function() {
            var item = this;
            setTimeout( function(){
                $(item).addClass('trigger');
            }, time)
            time += 200;
        });
    } else {
        $(elem).find('.animate-bulk-item').each(function() {
            var item = this;
            $(item).addClass('trigger');
        });
    }
}

function appears(elem) {
  var elementTop = elem.offset().top;
  // var elementBottom = elementTop + elem.outerHeight();

  var wh = $(window).height();
  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + wh;

  // If/else to adjust for translated element offset. It's considered to be always +- 100%
  if ($(elem).hasClass('slide-in-bottom')){
    return (elementTop - elem.outerHeight()) < (viewportBottom - wh/4);
  } else if ($(elem).hasClass('slide-in-top')) {
    return (elementTop + elem.outerHeight()) < (viewportBottom - wh/4);
  } else if ($(elem).hasClass('slide-in-bottom-small')) {
    return ((elementTop + elem.outerHeight()) / 2) < (viewportBottom - wh/4);
  } else {
      return elementTop < (viewportBottom - wh/4);
    }
};

function switch_infotab(action, element) {
    if (action === 'cenik') {
        $(element).siblings().removeClass('active');
        $(element).addClass('active');
        $(element).parent().siblings('.info-content').children('.section').removeClass('active');
        $(element).parent().siblings('.info-content').children('.section.cenik').addClass('active');
    }
    if (action === 'medailon') {
        $(element).siblings().removeClass('active');
        $(element).addClass('active');
        $(element).parent().siblings('.info-content').children('.section').removeClass('active');
        $(element).parent().siblings('.info-content').children('.section.medailon').addClass('active');
    }
}

function switch_team(number, element) {
    $('#team .member').removeClass('active');
    $(element).addClass('active');

    $('#team .right').find('.animate').removeClass('trigger');
    $('#team .team-foto').removeClass('delay0_5');
    $('#team .name, #team .info-content, #team .switcher').removeClass('animate, delay0_5');
    $('#team .team-info').removeClass('slide-in-right');
    $('#team .team-info').addClass('delay0_5, appear');

    setTimeout(function(){
        // $('#team .team-foto').addClass('delay0_5');
        $('#team .team-info').removeClass('delay0_5');
        $('#team .name, #team .info-content, #team .switcher').addClass('animate');
        $('.team-member').removeClass('active');
        var newMember = "#team-" + number;
        $(newMember).addClass('active');
        trigger_animations();
        $('html, body').animate({
            scrollTop: $("#team .inner").offset().top - 100
        }, 400);
    }, 500);
    setTimeout(function(){
        $('#team .right').find('.animate').addClass('trigger');
    }, 1000)
}

function scroll_to(element, offset = null) {
    $('html, body').animate({
        scrollTop: $("#"+element+" .inner-content").offset().top + offset
    }, 1000);
}
